import "./App.css";
import { useMemo } from "react";
import logo from './images/EternalBeings_Logo.svg';
import uzi from './images/spaceship_uzi.png';

import twitter from './images/twitter_eternal.svg';
import instagram from './images/eternal_instagram.svg';
import discord from './images/discord_eternal.svg';

import planet1 from './images/planet1.png';
import planet2 from './images/planet2.png';
import planet3 from './images/planet3.png';
import planet4 from './images/planet4.png';
import planet5 from './images/planet5.png';
import env from './env.json'
import mintImage from './images/mintImage.png'

import connectWalletBtn from './images/connectbutton-01.png';
import mintWindowImg from './images/WINDOW-01.png';
import mintWindowImg2 from './images/MINTWINDOW.png';
import JSConfetti from 'js-confetti'

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";











const treasury = new anchor.web3.PublicKey(
  env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  env.REACT_APP_CANDY_MACHINE_ID!
);

const network = env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const jsConfetti = new JSConfetti()


const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet()],
    []
  );

  return (


      <div className="container">

<div className="stars"></div>
  <div className="twinkling"></div>
  <div className="clouds"></div>

  <header id="header" className="">
    <div className="container d-flex align-items-center">

      <a data-aos="zoom-in-down" href="index.html" className="logo mr-auto"><img src={logo} alt=""/></a>

    </div>
  </header>

  

  <main id="main">

    <section id="mint" className="section-bg" data-aos="fade-down">
        <div className="section-title pb-0">
          <h3 className="pb-5 biggerH3">MINT BABY UZI</h3>          
        </div>
      <div className="img-wrapper windowBtn">
        {/* <img src={mintWindowImg2} alt={"window"} className="img-responsive"/> */}
        <img src={mintImage} className="mintImage"/>
        <div className="img-overlay">
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
              <WalletDialogProvider>
                <Home
                      candyMachineId={candyMachineId}
                      config={config}
                      connection={connection}
                      startDate={startDateSeed}
                      treasury={treasury}
                      txTimeout={txTimeout}
                    />
              </WalletDialogProvider>
              </WalletProvider>
            </ConnectionProvider>
            </div>
        </div>
    </section>

    <section id="hero" className="d-flex align-items-center center-password">

    <div className="password-footer-icons">
      <a data-aos="zoom-in" data-aos-offset="-500" className="footer-icon" href="https://discord.io/eternalbeings" target="_blank"><img className="footer-logo" src={discord}/></a>
      <a data-aos="zoom-in" data-aos-offset="-500" className="footer-icon" href="https://twitter.com/eternalbeingnft" target="_blank"><img className="footer-logo" src={twitter} /></a>
      <a data-aos="zoom-in" data-aos-offset="-500" className="footer-icon" href="https://instagram.com/eternal.beings.nft" target="_blank"><img className="footer-logo" src={instagram} /></a>
    </div>

    <div className="countdown absolute-countdown">        
      {/* <div data-aos="fade-down" className="clock-wrapper-target">  
        <div className="clock-title">SOLD OUT</div>
      </div>  */}
    </div> 
    <div className="container push-up" data-aos="zoom-out" data-aos-delay="100">      
      <div className="row reverse-mobile">
        <div className="push-back">
          <div className="spaceship-container-hero">
            <img src={uzi} className="w-spaceship password-spaceship"/>
            <div className="sol-amount">2.5 SOL</div>
          </div>
        </div>

      </div>      
    </div>
  </section>
    <section id="roadmap" className="about section-bg">
      <div className="container" data-aos="fade-up">

        <div className="separate-line"></div>
        <div className="section-title pb-0">
          <h3>ROADMAP</h3>          
        </div>

        <div className="row">
          <div className="col-12">

            <ul className="timeline">
              <li className="timeline-event">
                <img className="timeline-event-icon" src={planet1} />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">10%</p>
                  <h3>GENESIS</h3>
                  <p>
                    The launch on 09/27/2021 will mark just the beginning of Eternal Beings as our post launch marketing strategy will be deployed rapidly. The second phase will begin utilizing more celebrity ambassadors and marketing tactics that are still under wraps. 
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <img className="timeline-event-icon" src={planet2}/>
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">30%</p>
                  <h3>UNRAVELING</h3>
                  <p>
                    We will be offering an exclusive Lil Uzi Vert performance and backstage access experience to one lucky Eternal Beings NFT holder! The only rules are you MUST hold an Eternal Beings NFT at the time of our random snapshot and keep your eyes peeled for announcements post-launch!
                  </p>
                  </div>
              </li>
              <li className="timeline-event">
                <img className="timeline-event-icon" src={planet3}/>
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">50%</p>
                  <h3>SOLIDIFY</h3>
                  <p>
                    Eternal Beings begins limited merchandising drop that will be available to Eternal Being NFT holders. We had the chance to really sit down and make sure this merch delivers on creativity, quality, and exclusivity. Stay tuned we can’t wait to see our community sporting the merch!
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <img className="timeline-event-icon" src={planet4}/>
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">80%</p>
                  <h3>EXPANSION</h3>
                  <p>
                    We will begin developing an exclusive collection for those who are holding an Eternal Being, this collection will be separate from the Baby Uzi’s and will be a VERY limited Pink Tape collection which a % of proceeds will be donated to a Breast Cancer charity live on our Instagram. 
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <img className="timeline-event-icon" src={planet5}/>
                <div className="timeline-event-copy pb-0">
                  <p className="timeline-event-thumbnail">100%</p>
                  <h3>TAKEOVER</h3>
                  <p>
                    All Eternal Beings holders will be airdropped a Baby Uzi NFT 2-3 weeks after launch, a snapshot of all Eternal Beings NFT holders will be randomly taken during this time period granting those who held the reward of a Baby Uzi NFT being airdropped.
                  </p>
                </div>
              </li>
            </ul>  

          </div>
        </div>
        </div>
      </section>
    </main> 

  <div id="preloader">
    <div className="inner-preloader">
    </div>
  </div>
  <a href="#" className="back-to-top"><i className="icofont-simple-up"></i></a>
    
      </div>


  );
};

export default App;
